<template>
  <section class="about">
    <v-img
      v-if="info.banners[0]"
      :src="info.banners[0].img"
      class="top-banner"
      >
        <v-layout
          align-center
          fill-height
          justify-center
          style="position: relative; text-align: center;"
        >
          <v-card class="text-center" style="background:none !important; box-shadow: none; clear: both;">
            <v-card-text>
              <div style="color:#333; font-size: 48px;"></div> <br/>
            </v-card-text>
            <v-card-actions>
            </v-card-actions>
          </v-card>
          <v-flex style="position: absolute; bottom: 40px;" class="ctk-about-submenu">
            <template>
              <v-tabs
                v-model="tabSubMenu"
                light
              >
                <v-tab
                  v-for="(menu, k) in subMenu"
                  :key="k"
                  :href="'#tab-submenu-' + k"
                >
                  {{menu}}
                </v-tab>
              </v-tabs>
            </template>
          </v-flex>
        </v-layout>
      </v-img>
    <v-container  grid-list-md text-center class="ctk-div">
      <v-tabs-items v-model="tabSubMenu">
        <!--公司介绍-->
        <v-tab-item value="tab-submenu-0" >
          <v-container
            fluid
            grid-list-sm
          >
            <v-layout wrap>
              <v-flex class="text-left">
                <span v-html="about.introduction">{{about.introduction}}</span>
              </v-flex>
            </v-layout>
          </v-container>
        </v-tab-item>
        <!--发展历程-->
        <v-tab-item value="tab-submenu-1" >
          <v-container
            fluid
            grid-list-sm
          >
            <v-layout wrap>
              <v-flex>
                <template>
                  <v-timeline :reverse="reverse" :dense="$vuetify.breakpoint.smAndDown">
                    <v-timeline-item
                      class="text-left"
                      v-for="(tl, n) in about.timeline"
                      :key="n"
                    >
                      <span slot="opposite" style="color:#076db1;font-size:30px;font-weight:bold;">{{tl.ymd.substring(0,4)}}</span>
                      <v-card class="elevation-2">
                        <v-card-text class="white text--primary">
                          <p>{{tl.desc}}</p>
                          <v-img :src="tl.img" style="max-height:280px;"></v-img>
                        </v-card-text>
                      </v-card>
                    </v-timeline-item>
                  </v-timeline>
                </template>
                <!--<template class="ctk-timeline-div">
                    <v-timeline
                      dense
                      align-top
                      class="ctk-timeline"
                    >
                      <v-timeline-item
                        left
                        fill-dot
                        class="text-left"
                        v-for="(tl, n) in about.timeline"
                        :key="n"
                      >
                        <template v-slot:icon>
                          <v-avatar>
                            {{tl.ymd.substring(0,4)}}
                          </v-avatar>
                        </template>
                        <span slot="opposite">{{tl.ymd}}</span>
                        <v-card class="elevation-2">
                          <v-card-text>
                            <v-layout>
                              <v-flex  xs8 sm8 md8 lg6>
                                {{tl.desc}}
                              </v-flex>
                              <v-flex  xs4 sm4 md4 lg4>
                                <v-img :src="tl.img"></v-img>
                              </v-flex>
                            </v-layout>
                            <div style="position:absolute; top: 20px; right:20px;">
                              <span style="font-size: 64px; color: #0080E6;">{{tl.ymd.substring(8,10)}}</span>
                              <span style="font-size: 16px; color: #0080E6;">{{tl.ymd.substring(5,7)}}</span>
                            </div>
                          </v-card-text>
                        </v-card>
                      </v-timeline-item>
                    </v-timeline>
                </template>-->
              </v-flex>
            </v-layout>
          </v-container>
        </v-tab-item>
        <v-tab-item value="tab-submenu-2" >
          <v-container
            fluid
            grid-list-sm
          >
            <v-layout wrap>
              <v-flex class="text-left">
                <span v-html="about.culture">{{about.culture}}</span>
              </v-flex>
            </v-layout>
          </v-container>
        </v-tab-item>
        <!--合作客户-->
        <v-tab-item value="tab-submenu-3" >
          <v-container
            fluid
            grid-list-sm
          >
            <v-layout wrap>
              <!--<v-flex>
                <carousel-3d autoplay>
                  <slide :index="i" :key="i" v-for="(link, i) in info.links">
                      <img :src="link.logo" v-if="link.logo">
                  </slide>
                </carousel-3d>
              </v-flex>-->
              <template v-if="innerWidth > 960">
                <v-container mb-12>
                  <v-row
                    v-for="n in 5"
                    :key="n"
                    no-gutters
                  >
                    <template v-if="n===1">
                      <v-col
                        cols="2"
                        offset-md="5"
                      >
                        <v-flex
                          class="ma-3"
                          tile
                          v-if="info.links[0]"
                        >
                          <v-img :src="info.links[0].logo" class="ctk-customer-logo"></v-img>
                        </v-flex>
                      </v-col>
                    </template>
                    <template v-if="n===2">
                      <v-col
                        v-for="i in [2,3,4]"
                        cols="2"
                        :offset-md="i === 2?3:0"
                        :key="i"
                      >
                        <v-flex
                          class="ma-3"
                          tile
                          v-if="info.links[i-1]"
                        >
                          <v-img :src="info.links[i-1].logo" class="ctk-customer-logo"></v-img>
                        </v-flex>
                      </v-col>
                    </template>
                    <template v-if="n===3">
                      <v-col
                        v-for="i in [5,6,7,8]"
                        cols="2"
                        :offset-md="i === 5?2:0"
                        :key="i"
                      >
                        <v-flex
                          class="ma-3"
                          tile
                          v-if="info.links[i-1]"
                        >
                          <v-img :src="info.links[i-1].logo" class="ctk-customer-logo"></v-img>
                        </v-flex>
                      </v-col>
                    </template>
                    <template v-if="n===4">
                      <v-col
                        v-for="i in [9,10,11]"
                        cols="2"
                        :offset-md="i === 9?3:0"
                        :key="i"
                      >
                        <v-flex
                          class="ma-3"
                          tile
                          v-if="info.links[i-1]"
                        >
                          <v-img :src="info.links[i-1].logo" class="ctk-customer-logo"></v-img>
                        </v-flex>
                      </v-col>
                    </template>
                    <template v-if="n===5">
                      <v-col
                        cols="2"
                        offset-md="5"
                      >
                        <v-flex
                          class="ma-3"
                          tile
                          v-if="info.links[11]"
                        >
                          <v-img :src="info.links[11].logo" class="ctk-customer-logo"></v-img>
                        </v-flex>
                      </v-col>
                    </template>
                  </v-row>
                  <v-flex class="text-center grey--text text--darken-1" mt-2>(部分客户展示，排名不分先后)</v-flex>
                </v-container>
              </template>
              <template v-else>
                <v-flex
                  xs6 sm6
                  v-for="(link, i) in info.links"
                  :key="i"
                >
                  <img :src="link.logo" v-if="link.logo" style="width:100%;">
                </v-flex>
              </template>
            </v-layout>
          </v-container>
        </v-tab-item>
      </v-tabs-items>
    </v-container>
  </section>
</template>
<script>
// import { Carousel3d, Slide } from 'vue-carousel-3d'
export default {
  data: () => ({
    pageId: 1,
    tabSubMenu: null,
    introduce: null,
    introduceText: null,
    subMenu: null,
    innerWidth: window.innerWidth,
    about: {
      'introduction': null
    }
  }),
  components: {
    /*
    Carousel3d,
    Slide
    */
  },
  created () {
    document.querySelector('#menu_about').classList.add('v-btn--active')
    this.getPageData()
    this.getAbout()
  },
  watch: {
    '$store.getters.companyInfo': {
      handler: function (val, oldVal) {
        let locale = localStorage.getItem('locale')
        if (!locale) {
          locale = 'cn'
        }
        this.info.banners = val.banner[this.pageId]
      },
      deep: true
    },
    tabSubMenu: {
      handler: function (val, oldVal) {
        if (val === 'tab-submenu-3') {
          let lks = this.$store.getters.companyInfo.link
          let links = []
          if (lks) {
            let i = null
            for (i in lks) {
              if (lks[i].logo) {
                links.push(lks[i])
              }
            }
          }
          this.info.links = links
        }
      },
      deep: true
    }
  },
  computed: {
    info () {
      let banners = {}
      banners[this.pageId] = []
      let locale = localStorage.getItem('locale')
      if (!locale) {
        locale = 'cn'
      }
      if (this.$store.getters.companyInfo.banner) {
        banners = this.$store.getters.companyInfo.banner[this.pageId]
      }
      let links = []
      if (this.$store.getters.companyInfo.links) {
        links = this.$store.getters.companyInfo.links
      }
      return {
        links: links,
        banners: banners
      }
    }
  },
  methods: {
    getPageData () {
      this.introduce = this.$t('about.introduce')
      this.subMenu = this.$t('about.menu')
    },
    getAbout () {
      this.https.get('about').then(response => {
        if (response.code === 0) {
          this.about = response.data
        }
      })
    }
  }
}
</script>
